import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 56 56';
const id = 'delete_outline_56';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" id="delete_outline_56"><path fill-rule="evenodd" clip-rule="evenodd" d="M21.398 18.003a1.5 1.5 0 00-1.395 1.599l1.5 22a1.5 1.5 0 102.994-.204l-1.5-22a1.5 1.5 0 00-1.599-1.395zm13.204 0a1.5 1.5 0 011.395 1.599l-1.5 22a1.5 1.5 0 11-2.994-.204l1.5-22a1.5 1.5 0 011.599-1.395z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M5 9a1.5 1.5 0 100 3h4.63l2.862 31.42c.112 1.234.204 2.247.345 3.068.146.853.36 1.618.779 2.325a6.5 6.5 0 002.8 2.557c.742.353 1.523.497 2.386.565.83.065 1.847.065 3.086.065h12.224c1.239 0 2.255 0 3.086-.065.863-.068 1.644-.212 2.386-.565a6.5 6.5 0 002.8-2.557c.419-.707.633-1.472.78-2.325.14-.821.232-1.833.344-3.067L46.37 12H51a1.5 1.5 0 000-3H36.368a8.503 8.503 0 00-16.736 0H5zm10.474 34.09c.12 1.307.202 2.201.32 2.893.115.674.247 1.037.403 1.3a3.5 3.5 0 001.508 1.378c.277.131.65.23 1.332.283.7.055 1.598.056 2.91.056h12.106c1.312 0 2.21-.001 2.91-.056.682-.053 1.055-.152 1.332-.283a3.5 3.5 0 001.508-1.377c.157-.264.288-.627.403-1.3.118-.693.2-1.587.32-2.895L43.357 12H12.643l2.831 31.09zM28 5a5.502 5.502 0 00-5.293 4h10.586A5.502 5.502 0 0028 5z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon56DeleteOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon56DeleteOutline: FC<Icon56DeleteOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 56,
    height: !isNaN(props.height) ? +props.height : 56,
  }));
};

(Icon56DeleteOutline as any).mountIcon = mountIcon;

export default Icon56DeleteOutline;
